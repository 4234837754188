import Button from '@/components/Button';
import { useGameTeaser, type GameTeaserProps } from '@/components/GameTeaser';
import LinkWrapper from '@/components/LinkWrapper';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';
import classnames from 'classnames';
import Image from 'next/image';

export default function NavigationGameTeaser(
  props: GameTeaserProps<{
    link: string;
  }>
) {
  const { config, state, theme } = useGameTeaser(props);
  return config ? (
    <div
      className={classnames(
        'NavigationGameTeaser',
        `NavigationGameTeaser--${theme}`,
        {
          'NavigationGameTeaser--alternative': !!config.alternativeDesign,
        }
      )}
    >
      <header className="NavigationGameTeaser__header">
        <span className="NavigationGameTeaser__subtitle">{state.subtitle}</span>
        <strong className="NavigationGameTeaser__jackpot">
          {state.jackpot}
        </strong>
        {props.link && props.link !== '#' && (
          <small
            className="NavigationGameTeaser__chance"
            dangerouslySetInnerHTML={replaceAbbreviations(config.chanceDesc)}
          />
        )}
      </header>
      {props.link && props.link !== '#' && (
        <LinkWrapper
          className="NavigationGameTeaser__link"
          tabIndex={0}
          href={props.link}
        >
          <Button variant={config.button} fullWidth={true}>
            {config.title} spielen
          </Button>
        </LinkWrapper>
      )}

      {theme === 'lotto6aus49' && (
        <div className="NavigationGameTeaser__image">
          <Image
            alt="Lotto 6aus49 das Original"
            src="/assets/images/lotto-6aus49-stamp-70.webp"
            className="Footer__image"
            loading="lazy"
            width={253}
            height={0}
          />
        </div>
      )}
    </div>
  ) : null;
}
